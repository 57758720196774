// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-content {
  width: 90% !important;
}

@media (min-width: 420px) {
  .popup-content {
    max-width: 320px;
  }
}
@media (min-width: 1024px) {
  .popup-content {
    max-width: 530px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/TestScore.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".popup-content {\n  width: 90% !important;\n}\n\n@media (min-width: 420px) {\n  .popup-content {\n    max-width: 320px;\n  }\n}\n@media (min-width: 1024px) {\n  .popup-content {\n    max-width: 530px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
