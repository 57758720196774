import { Fragment, useEffect, useMemo, useState } from "react";
import Countdown, { CountdownRenderProps, zeroPad } from "react-countdown";
import { AiOutlinePushpin } from "react-icons/ai";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import PageLoader from "src/components/Loader/PageLoader";
import { useSubmitExamMutation } from "src/lib/endpoints/question/question.api";
import {
  Question,
  QuestionData,
} from "src/lib/endpoints/question/question.type";
import { selectTestState } from "src/lib/features/test/testSlice";
import { useTypedSelector } from "src/lib/store/store";
import Button from "../../components/Button/Button";
import TestScoreModal from "../../components/Modal/TestScoreModal";

const TakeTest = () => {
  const [showModal, setShowModal] = useState(false);
  const [questionId, setQuestionId] = useState("");
  const { submitExam } = useSubmitExamMutation();
  const { questionData } = useTypedSelector(selectTestState);
  const { questions, count, testDuration } = questionData as QuestionData;
  const [questionNo, setQuestionNo] = useState(1);
  const [answers, setAnswers] = useState<Record<string, any>>({});
  const [choosenAnswer, setChoosenAnswer] = useState("");
  const [isCompleted, setIscompleted] = useState(false);

  const onSubmitHandler = async () => {
    const question = questions[questionNo - 1];
    const data = {
      questionID: question.id,
      framework_id: question.framework_id,
      language_id: question.language_id,
      skills_id: question.skills_id,
      question_dependency: question.question_dependency,
      option: choosenAnswer,
    };
    const newAnswer = answers;
    newAnswer[question.id!] = data;
    setAnswers(newAnswer);

    if (isCompleted) return;
    if (questions.length === questionNo) {
      submitExam(
        {
          answers: Object.values(newAnswer),
          count: count,
        },
        {
          onSuccess: () => {
            setShowModal(true);
            setIscompleted(true);
          },
          onError: () => {},
        }
      );
    } else {
      setChoosenAnswer("");
      setQuestionNo((prev) => prev + 1);
    }
  };

  const onCloseHandler = () => {
    setShowModal(false);
  };

  const renderer = ({
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    if (completed) {
      // Render a completed state
      return (
        <span>
          {"00"}:{"00"}
        </span>
      );
      // return <span>You are good to go!</span>;
    } else {
      return (
        <span>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  const shuffle = (array: any[]) => {
    return array.sort(() => Math.random() - Math.random());
  };

  const questionDetails = useMemo(() => {
    if (questions.length + 1 === questionNo)
      return {
        id: "",
        question: "",
        paragraphs: [],
      };
    const question = questions[questionNo - 1] as Question;
    setQuestionId(question?.id ?? "");

    return {
      id: question.id,
      question: question?.question ?? "",
      paragraphs: shuffle([
        ["correctOption1", question?.correctOption1],
        ["option2", question?.option2],
        ["option3", question?.option3],
        ["option4", question?.option4],
        // ["option5", question?.option5],
      ]),
    };
  }, [questionNo]);

  const countDown = useMemo(
    () => (
      <Countdown
        date={Date.now() + +(testDuration || 0) * 60 * 1000}
        renderer={renderer}
        autoStart={true}
        onComplete={async ({ minutes, seconds }) => {
          if (testDuration === undefined) return;
          if (isCompleted) return;
          setIscompleted(true);
          submitExam(
            {
              answers: Object.values(answers),
              count: count,
            },
            {
              onSuccess: () => {
                setShowModal(true);
                setIscompleted(true);
              },
              onError: () => {},
            }
          );
        }}
      />
    ),
    [testDuration]
  );

  useEffect(() => {
    const stopPropagation = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "Data will be lost if you leave the page, are you sure?";
    };

    window.addEventListener("beforeunload", stopPropagation);

    return () => {
      window.removeEventListener("beforeunload", stopPropagation);
    };
  }, []);

  if (!questions || questions.length === 0 || testDuration === undefined)
    return (
      <Fragment>
        <PageLoader />
      </Fragment>
    );

  return (
    <div>
      <div className="h-full my-auto">
        {showModal && (
          <TestScoreModal open={showModal} onClose={onCloseHandler} />
        )}
        {questions.length + 1 !== questionNo && (
          <div className="flex flex-col space-y-10 pt-10 pb-20 items-center">
            <div className="flex items-center justify-between w-full">
              <div className="flex gap-7 items-center font-medium">
                <AiOutlinePushpin size={21} />
                <p>
                  {questionNo}/{questions.length}
                </p>
              </div>
              {/* COUNTDOWN */}
              {countDown}
            </div>
            {/* QUESTION */}
            <h1 className="text-lg font-medium">{questionDetails.question}</h1>
            <div className="flex flex-col gap-9 w-full justify-start">
              {questionDetails.paragraphs.map(([option, paragraph], index) => (
                <div
                  key={option}
                  onClick={() => {
                    setChoosenAnswer(option);
                  }}
                  className="flex gap-5 items-center hover:cursor-pointer"
                >
                  <div>
                    {choosenAnswer === option ? (
                      <MdRadioButtonChecked />
                    ) : (
                      <MdRadioButtonUnchecked />
                    )}
                  </div>
                  <p>{["A", "B", "C", "D"][index]}:</p>
                  <p>{paragraph}</p>
                </div>
              ))}
            </div>
            {/* <div className="w-full">
            <MultiChoiceRadio
              answer={answers[questions[questionNo].id as any]}
              chosenAnswer={choosenAnswer}
              setChoosenAnswer={setChoosenAnswer}
              paragraphs={questionDetails.paragraphs ?? []}
            />
          </div> */}
            <div className="flex justify-end items-center w-full">
              {/* <Button
              onClick={() =>
                setQuestionNo((prev: number) => (prev > 1 ? prev - 1 : prev))
              }
              className={`
                bg-white text-primary
            `}
            >
              Prev
            </Button> */}
              <Button
                onClick={onSubmitHandler}
                className={`
                bg-primary text-white
            `}
              >
                {questions?.length === questionNo ? "Submit" : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TakeTest;
