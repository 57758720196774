import { AxiosError } from "axios";
import axiosInstance from "src/lib/config/axios";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { GenericResponse, Question } from "./question.type";

export const useResultQuery = () => {
  const { data, error, isLoading, mutate } = useSWR<any, AxiosError, string>(
    `/dev/getMyResult`,
    (url: string) => axiosInstance.get(url).then((res: any) => res.data.data)
  );

  return {
    result: data,
    isLoading,
    error,
    getResult: mutate,
  };
};

export const useSubmitExamMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    GenericResponse,
    AxiosError,
    `/dev/submitExam/droomwork`,
    any,
    any
  >(`/dev/submitExam/droomwork`, (url: string, { arg }) =>
    axiosInstance.put(url, arg).then((res) => res.data)
  );

  return {
    data,
    isLoading: isMutating,
    submitExam: trigger,
  };
};

export const useCbtDetailsQuery = () => {
  const { data, error, isLoading, mutate } = useSWR<
    any,
    AxiosError,
    `/dev/getAllMyCbtDetails`
  >(`/dev/getAllMyCbtDetails`, (url: string) =>
    axiosInstance.get(url).then((res) => res.data?.data)
  );

  return {
    cbtDetails: data,
    isLoading,
    error,
    getCbtDetails: mutate,
  };
};
