// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader__container {
  width: 100px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: white;
}

.loader {
  width: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
  transform: translateX(-38px);
  animation: d1 0.5s infinite alternate linear;
  margin: auto;
}

@keyframes d1 {
  50% {
    box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
  }
  100% {
    box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,eAAe;EACf,kBAAkB;EAClB,kDAAkD;EAClD,4BAA4B;EAC5B,4CAA4C;EAC5C,YAAY;AACd;;AAEA;EACE;IACE,oDAAoD;EACtD;EACA;IACE,kDAAkD;EACpD;AACF","sourcesContent":[".loader__container {\n  width: 100px;\n  border-radius: 12px;\n  display: flex;\n  justify-content: center;\n  margin-top: 10px;\n  color: white;\n}\n\n.loader {\n  width: 4px;\n  aspect-ratio: 1;\n  border-radius: 50%;\n  box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;\n  transform: translateX(-38px);\n  animation: d1 0.5s infinite alternate linear;\n  margin: auto;\n}\n\n@keyframes d1 {\n  50% {\n    box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;\n  }\n  100% {\n    box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
